@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap");
body {
  background: #000;
}

.shimmer {
  font-family: "Times New Roman";
  font-size: 7.6rem;
  font-style: italic;
  animation: shimmer 5s infinite linear;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
:root {
  --primary: #936BFF;
  --primary-dark: #2a1665;
  --primary-light: #bba3ff;
  --bg:white;
  --bg_opposite:#E3E0ED;
  --h1:#202937;
  --h2:#384151;
  --h3:#384151;
  --h4:#384151;
  --h5:#384151;
  --h6:#384151;
  --text_light:#6B7280;
  --text:#6B7280;
  --text_dark:#384151;
  --blockpara:#6B7280;
  --border-subtle: #D2D5DB;
  --border-moderate: #9CA3B0;
  --border-bold: #F9FAFB;
  --pscard_bg:#F9FAFB;
  --links:blue;
}

[data-theme=dark] {
  --bg: black;
  --bg_opposite: white;
  --pscard_bg:#384151;
  --h1:#F3F4F6;
  --h2:#F3F4F6;
  --h3:#F3F4F6;
  --h4:#F3F4F6;
  --h5:#F3F4F6;
  --h6:#F3F4F6;
  --text_light:#F3F4F6;
  --text:#F3F4F6;
  --text_dark:#F3F4F6;
  --border-subtle: #D2D5DB;
  --border-moderate: #9CA3B0;
  --border-bold: #F9FAFB;
}

h1 {
  color: var(--h1);
}

h2 {
  color: var(--h2);
}

h3 {
  color: var(--h3);
}

h4 {
  color: var(--h4);
}

h5 {
  color: var(--h5);
}

h6 {
  color: var(--h6);
}

p {
  color: var(--text);
}

li {
  color: var(--text);
}

* {
  transition: background-color 0.3s, color 0.3s;
}

.master-wrapper {
  background-color: var(--bg_opposite);
}

.wrapper {
  background-color: var(--bg);
}

html {
  background-color: var(--bg);
  overflow-x: hidden;
}

:root {
  --primary: #936BFF;
  --primary-dark: #2a1665;
  --primary-light: #bba3ff;
  --bg:white;
  --bg_opposite:#E3E0ED;
  --h1:#202937;
  --h2:#384151;
  --h3:#384151;
  --h4:#384151;
  --h5:#384151;
  --h6:#384151;
  --text_light:#6B7280;
  --text:#6B7280;
  --text_dark:#384151;
  --blockpara:#6B7280;
  --border-subtle: #D2D5DB;
  --border-moderate: #9CA3B0;
  --border-bold: #F9FAFB;
  --pscard_bg:#F9FAFB;
  --links:blue;
}

[data-theme=dark] {
  --bg: black;
  --bg_opposite: white;
  --pscard_bg:#384151;
  --h1:#F3F4F6;
  --h2:#F3F4F6;
  --h3:#F3F4F6;
  --h4:#F3F4F6;
  --h5:#F3F4F6;
  --h6:#F3F4F6;
  --text_light:#F3F4F6;
  --text:#F3F4F6;
  --text_dark:#F3F4F6;
  --border-subtle: #D2D5DB;
  --border-moderate: #9CA3B0;
  --border-bold: #F9FAFB;
}

h1 {
  color: var(--h1);
}

h2 {
  color: var(--h2);
}

h3 {
  color: var(--h3);
}

h4 {
  color: var(--h4);
}

h5 {
  color: var(--h5);
}

h6 {
  color: var(--h6);
}

p {
  color: var(--text);
}

li {
  color: var(--text);
}

* {
  transition: background-color 0.3s, color 0.3s;
}

.master-wrapper {
  background-color: var(--bg_opposite);
}

.wrapper {
  background-color: var(--bg);
}

html {
  background-color: var(--bg);
  overflow-x: hidden;
}

.permanent-marker-regular {
  font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
}

html {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Instrument Sans", serif;
  /* Set the font family */
  font-optical-sizing: auto;
  /* Enable automatic optical sizing */
  font-variation-settings: "wdth" 100;
  /* Adjust the width axis of the variable font */
}

p,
li {
  font-family: "Inter Tight", sans-serif;
}

h1 {
  font-family: "Inter Tight", sans-serif !important;
  font-size: 59.40688px;
  font-weight: 550;
  letter-spacing: 0em;
  line-height: 1;
}

h2 {
  font-size: 45.6976px;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.2;
}

h3 {
  font-size: 35.152px;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.2;
}

h4 {
  font-size: 27.04px;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.2;
}

h5 {
  font-size: 20.8px;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.5;
}

h6 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.5;
}

p.lg {
  font-size: 20.8px;
  font-weight: 300;
  line-height: 1.7;
}

p {
  font-size: 19.2px;
  font-weight: 360;
  letter-spacing: 0.015em;
  line-height: 1.7;
}

p.sm {
  font-size: 14.4px;
  font-weight: 350;
  line-height: 1;
  letter-spacing: 0.02em;
}

button {
  font-size: 19.2px;
  font-weight: 500;
  letter-spacing: 0em;
}

.bigtext {
  font-family: "Inter Tight", sans-serif;
  font-size: 6.22rem;
  font-weight: 400;
  letter-spacing: -0.2rem;
  line-height: 97%;
  color: var(--h1);
}

.subtext-under-bigtext, .currenlydesigningtext {
  font-size: 1.5rem;
  max-width: 50% !important;
  font-weight: 320 !important;
  letter-spacing: 0rem !important;
  color: var(--text_light);
}

.junaid {
  font-style: italic;
  font-size: 7.6rem;
  position: relative;
  overflow: hidden;
  letter-spacing: -0.3rem;
}

.w100 {
  font-weight: 100;
}

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.w800 {
  font-weight: 800;
}

.w900 {
  font-weight: 900;
}

.blockpara, .blockparaBlack {
  font-family: "Inter Tight", sans-serif !important;
  font-weight: 350 !important;
  line-height: 1.5 !important;
  color: var(--blockpara);
  letter-spacing: 0.015em !important;
}

.blockparaBlack {
  color: var(--grey700) !important;
}

li {
  font-size: 19.2px;
  font-weight: 360;
  letter-spacing: 0.015em;
  line-height: 1.7;
  padding-left: 0.5rem;
}

.currenlydesigningtext {
  max-width: 100% !important;
  white-space: nowrap;
  /* Prevents line breaks */
}

.radius-lg {
  border-radius: 1.5rem;
}

.radius-md {
  border-radius: 1rem;
}

.radius-sm {
  border-radius: 0.5rem;
}

.bottom-xs {
  margin-bottom: 0.125rem !important;
}

.bottom-sm {
  margin-bottom: 0.25rem !important;
}

.bottom-md {
  margin-bottom: 0.5rem !important;
}

.bottom-lg {
  margin-bottom: 1rem !important;
}

.bottom-xl {
  margin-bottom: 2rem !important;
}

.bottom-2xl {
  margin-bottom: 4rem !important;
}

.bottom-3xl {
  margin-bottom: 8rem !important;
}

.bottom-4xl {
  margin-bottom: 16rem !important;
}

.bottom-5xl {
  margin-bottom: 32rem !important;
}

.top-xs {
  margin-top: 0.125rem;
}

.top-sm {
  margin-top: 0.25rem;
}

.top-md {
  margin-top: 0.5rem;
}

.top-lg {
  margin-top: 1rem;
}

.top-xl {
  margin-top: 2rem;
}

.top-2xl {
  margin-top: 4rem;
}

.top-3xl {
  margin-top: 8rem;
}

.top-4xl {
  margin-top: 16rem;
}

.top-5xl {
  margin-top: 32rem;
}

.left-xs {
  margin-left: 0.125rem;
}

.left-sm {
  margin-left: 0.25rem;
}

.left-md {
  margin-left: 0.5rem;
}

.left-lg {
  margin-left: 1rem;
}

.left-xl {
  margin-left: 2rem;
}

.left-2xl {
  margin-left: 4rem;
}

.left-3xl {
  margin-left: 8rem;
}

.left-4xl {
  margin-left: 16rem;
}

.left-5xl {
  margin-left: 32rem;
}

.right-xs {
  margin-right: 0.125rem;
}

.right-sm {
  margin-right: 0.25rem;
}

.right-md {
  margin-right: 0.5rem;
}

.right-lg {
  margin-right: 1rem;
}

.right-xl {
  margin-right: 2rem;
}

.right-2xl {
  margin-right: 4rem;
}

.right-3xl {
  margin-right: 8rem;
}

.right-4xl {
  margin-right: 16rem;
}

.right-5xl {
  margin-right: 32rem;
}

.image-radius {
  border-radius: 1.5rem;
}

.inner-piecesdiv-spacing {
  margin-bottom: 1rem;
}

.master-wrapper {
  width: 100%;
  height: 100%;
}

.textandarrow {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.getintouch-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.getintouch-title {
  font-weight: 400;
  color: var(--text);
}

.contact-container {
  display: flex;
  flex-direction: row;
  column-gap: 5rem;
  row-gap: 5rem;
  flex-wrap: wrap;
  margin: 1rem;
}

.arrow {
  width: 1.5rem;
  height: 1.5rem;
}

.letsmakeithappen {
  max-width: 25rem;
}

.footer-maxwidth-container-withflex {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: auto;
  justify-content: flex-start;
  padding: 4rem 0rem;
}

.opentowork {
  max-width: 18%;
}

.details-text {
  color: var(--para-md);
  font-weight: 400 !important;
}

.inner-contact-container {
  display: flex;
  flex-direction: row;
  column-gap: 4rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem 0 1rem 0;
}

.header-links-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.headerlinks {
  color: var(--para-md);
}

:root {
  --primary: #936BFF;
  --primary-dark: #2a1665;
  --primary-light: #bba3ff;
  --bg:white;
  --bg_opposite:#E3E0ED;
  --h1:#202937;
  --h2:#384151;
  --h3:#384151;
  --h4:#384151;
  --h5:#384151;
  --h6:#384151;
  --text_light:#6B7280;
  --text:#6B7280;
  --text_dark:#384151;
  --blockpara:#6B7280;
  --border-subtle: #D2D5DB;
  --border-moderate: #9CA3B0;
  --border-bold: #F9FAFB;
  --pscard_bg:#F9FAFB;
  --links:blue;
}

[data-theme=dark] {
  --bg: black;
  --bg_opposite: white;
  --pscard_bg:#384151;
  --h1:#F3F4F6;
  --h2:#F3F4F6;
  --h3:#F3F4F6;
  --h4:#F3F4F6;
  --h5:#F3F4F6;
  --h6:#F3F4F6;
  --text_light:#F3F4F6;
  --text:#F3F4F6;
  --text_dark:#F3F4F6;
  --border-subtle: #D2D5DB;
  --border-moderate: #9CA3B0;
  --border-bold: #F9FAFB;
}

h1 {
  color: var(--h1);
}

h2 {
  color: var(--h2);
}

h3 {
  color: var(--h3);
}

h4 {
  color: var(--h4);
}

h5 {
  color: var(--h5);
}

h6 {
  color: var(--h6);
}

p {
  color: var(--text);
}

li {
  color: var(--text);
}

* {
  transition: background-color 0.3s, color 0.3s;
}

.master-wrapper {
  background-color: var(--bg_opposite);
}

.wrapper {
  background-color: var(--bg);
}

html {
  background-color: var(--bg);
  overflow-x: hidden;
}

.back-to-top {
  background-color: #936BFF;
  text-decoration: none;
  cursor: pointer;
  width: 56px;
  height: 56px;
  z-index: 999;
  position: fixed;
  right: 32px;
  bottom: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.4);
}
.back-to-top:hover {
  background-color: #c0a8ff;
}
.back-to-top:active {
  box-shadow: none;
  background-color: #d60074;
}
.back-to-top svg.icon__arrow-up {
  fill: white;
  width: 32px;
  height: 32px;
}

body {
  min-height: 100vh;
}
body .box {
  padding: 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body .box-2 {
  background-color: #ddd;
}
body .box-3 {
  background-color: #ccc;
}
body .box-4 {
  background-color: #bbb;
}
body .box .title {
  margin: 0;
  text-align: center;
}

.hiringmanager {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.skills-section, .new-skills-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
}

.stickynote {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 3px;
  max-width: 400px;
  margin: auto;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.03), 0 6.7px 5.3px rgba(0, 0, 0, 0.05), 0 12.5px 10px rgba(0, 0, 0, 0.07), 0 22.3px 17.9px rgba(0, 0, 0, 0.09), 0 41.8px 33.4px rgba(0, 0, 0, 0.11), 0 100px 80px rgba(0, 0, 0, 0.15);
}
.stickynote p.lg {
  color: #2c2c2c;
  font-family: "Kalam", serif;
  font-weight: 700;
}
.stickynote p.md {
  color: #2c2c2c;
  font-family: "Kalam", serif;
  font-weight: 400;
  line-height: 150%;
  font-size: 1.2em;
}

.yellowSticky {
  background: linear-gradient(145deg, #ffeb3b, #fdd835); /* Darker gradient background */
}

.redSticky {
  background-color: #F16C7F;
  margin-top: 3rem;
}

.blueSticky {
  background-color: #6CD9FA;
}

.rotateright {
  transform: rotate(3deg);
}

.rotateleft {
  transform: rotate(-3deg);
}

.stickyContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media (max-width: 767px) {
  .stickyContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .skills-section, .new-skills-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4rem;
  }
  .stickynote {
    aspect-ratio: 1/1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 3px;
    width: 350px;
    margin: auto;
  }
  .stickynote p.lg {
    color: #2c2c2c;
    font-family: "Kalam", sans-serif;
    font-weight: 700;
  }
  .stickynote p.md {
    color: #2c2c2c;
    font-family: "Kalam", sans-serif;
    font-weight: 400;
    line-height: 180%;
    font-size: 1.2em;
  }
  .yellowSticky {
    background-color: #ffc000;
  }
  .redSticky {
    background-color: #F16C7F;
    margin-top: 3rem;
  }
  .blueSticky {
    background-color: #6CD9FA;
  }
  .rotateright {
    transform: rotate(3deg);
  }
  .rotateleft {
    transform: rotate(-3deg);
  }
}
.aboutme-section {
  max-width: 1400px;
  padding-top: 10rem;
  padding-bottom: 10rem;
  margin: auto;
}

.grid12px {
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 40px;
}

#aboutme-title {
  font-family: Kalam, sans-serif;
  font-size: 2rem;
}

.abtme-p {
  grid-column: 5/span 6;
}

.abtme-img1 {
  grid-column: 1/span 3;
  width: 90%;
  height: 25rem;
  border-radius: 12px;
  object-fit: cover;
}

.abtme-img2 {
  margin-top: 150px;
  grid-column: 1/span 2;
  width: 100%;
  height: 17rem;
  border-radius: 12px;
  object-fit: cover;
  transform: translateX(-3rem);
}

.abtme-img3 {
  grid-column: 5/span 4;
  width: 100%;
  height: 13rem;
  border-radius: 12px;
  object-fit: cover;
}

.abtme-img4 {
  grid-column: 10/span 3;
  width: 100%;
  height: 13rem;
  border-radius: 12px;
  object-fit: cover;
  margin-top: -150px;
  transform: translateX(4rem);
}

.below-title-spacing {
  margin-bottom: 1rem;
}

.title-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.imagegrid4-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

.grid-fullwidth1 {
  grid-row: 1/2;
  grid-column: 1/3;
  min-width: 100%;
}

.grid-halfwidth1 {
  /* This element will start at the 2nd row and 1st column and span 1 row and 1 column */
  grid-row: 2/3;
  grid-column: 1/2;
  min-width: 100%; /* Stretch the image to fit the width of the container */
  height: auto; /* Maintain aspect ratio */
}

.grid-halfwidth2 {
  grid-row: 2/3;
  grid-column: 2/3;
  min-width: 100%; /* Stretch the image to fit the width of the container */
  height: auto; /* Maintain aspect ratio */
}

.grid-fullwidth2 {
  grid-area: 3/1/4/3;
}

.img-fullwidth {
  max-width: 100%;
}

.twocol-para {
  columns: 2;
  gap: 1rem;
}

.project-details-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.role-section {
  display: flex;
  flex-direction: column;
  column-gap: 3rem;
}

.pieces-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
}

.project-content-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pieces-div-container {
  margin: auto;
  width: 100%;
  border: 0px solid red;
}

.aside-sub {
  margin-left: 0.5rem;
}

aside {
  min-width: 13rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 100vh;
  max-width: 12rem;
  position: sticky;
  top: 0;
  left: 0;
}

.backcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 12rem;
  max-width: 12rem;
}

.title-back {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.tocc a {
  text-decoration: none;
  color: var(--text);
  padding-top: 1rem;
  line-height: 1 !important;
  font-size: 14.4px !important;
  font-weight: 350 !important;
  letter-spacing: 0.02em !important;
}

.tocc li {
  list-style-type: none; /* Remove bullet points */
  padding-top: 0.5rem;
  line-height: 1 !important;
  padding-left: 0rem !important;
}

.active {
  font-weight: 400 !important;
  color: var(--primary) !important;
}

.proj-title {
  width: 100%;
  text-align: center !important;
}

.header-back-container {
  display: flex;
  flex-direction: row;
}

.backarr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-title {
  max-width: 800px;
}

.product-title-container {
  max-width: 80%;
  margin-left: 12rem;
}

.quoteblocksfc {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.quote-icon {
  height: auto;
  width: 2rem;
}

.crumbs {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

@media screen and (max-width: 700px) {
  aside {
    display: none;
  }
  .product-title-container {
    max-width: 100%;
    margin: 0;
  }
  .product-title {
    font-size: 3rem;
    margin: 4rem 0rem !important;
  }
}
#precision-section {
  height: 350vh;
}

.gradient_bg_reveal {
  background-color: #ffffff;
  background: linear-gradient(180deg, #FFF 0%, #BEDBFF 20%, #C7D2FF 40%, #DED6FF 60%, #E9D5FF 80%, #FFF 100%);
}

.footer {
  background-color: #000;
  padding: 20px;
  color: #fff;
  text-align: center;
}

:root {
  --transition: 0.2s;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.maxwidth-container-text {
  width: 680px;
}

.text-container {
  width: 750px;
  margin: auto;
}

.maxwidth-container-withflex {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.maxwidth-container-head3 {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: 1440px;
  align-items: flex-start;
}

.section-gap {
  margin-bottom: 5rem;
}

/* Pop */
@keyframes pop {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.pop {
  display: inline-block;
}
.pop:hover {
  animation-name: pop;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.arr {
  max-width: 1.5rem;
  aspect-ratio: 1/1;
}

.infobox {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

a {
  color: green;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 40px;
}

.project {
  aspect-ratio: 1/1;
  grid-column: span 6/span 6;
}

.project-margintop {
  margin-top: 6rem;
}

.project-imgbox {
  width: 100%;
  height: auto;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grow-rotate, .chipsskills {
  transition-duration: var(--transition);
  transition-property: transform;
}
.grow-rotate:hover, .chipsskills:hover {
  transform: scale(1.04) rotate(1deg);
}

.grow-rotate-opposite, .chipsskills-reverse {
  transition-duration: 0.1s;
  transition-property: transform;
}
.grow-rotate-opposite:hover, .chipsskills-reverse:hover {
  transform: scale(1.04) rotate(-2deg);
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
}

.grow-rotate-reverse {
  transition-duration: 0.1s;
  transition-property: transform;
  transform: scale(1.04) rotate(2deg);
}
.grow-rotate-reverse:hover {
  transform: scale(1.3) rotate(0deg);
}

.grow-rotate-small {
  transition-duration: 0.1s;
  transition-property: transform;
  transform: scale(1) rotate(2deg);
}
.grow-rotate-small:hover {
  transform: scale(1) rotate(0deg);
}

.maxwidth-container, .header-container, .maxwidth-container-withflex, .maxwidth-container-head3 {
  max-width: 1100px;
  margin: auto;
  padding: 1rem;
}

.wrapper {
  border: 0px solid red;
  transform-origin: center bottom 0px;
  transition: all 0.2s ease 0s;
  box-shadow: 0px 0px 113.4px 3px rgba(13, 4, 49, 0.08);
  border-radius: 0rem 0rem 6rem 6rem;
  padding-bottom: 6rem;
}

body.tight .wrapper {
  transform: translateY(0px) scale(0.8);
  cursor: pointer;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
.grid12 {
  display: grid;
  grid-template-columns: repeat(12, minmax(3rem, 1fr));
  gap: 1rem;
  border: 1px solid wheat;
}

.project1 {
  grid-column: span 6;
  height: 30px;
  background-color: #5c5c5c;
}

.project2 {
  grid-column: span 6;
  height: 30px;
  background-color: #5c5c5c;
}

.project3 {
  grid-column: span 6;
  height: 30px;
  background-color: #5c5c5c;
}

.project4 {
  grid-column: span 6;
  height: 30px;
  background-color: #5c5c5c;
}

.tiltedvid {
  width: 10rem;
  display: flex;
  pointer-events: none !important;
  aspect-ratio: 4/3;
  rotate: 3deg;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.vidinside {
  width: 100%;
  height: auto;
  display: block;
  pointer-events: none !important;
}

.bigbox {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bigbox {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#see-section {
  background: #936BFF;
  height: 300vh;
  width: 100%;
  border-radius: 0rem 0rem 0rem 0rem;
}

.mgb5 {
  margin-bottom: 5rem;
}

a {
  text-decoration: none;
}

.demotextscale {
  padding-top: 5rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.demotextscale div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.demotextscale .border {
  border: 0px solid red !important;
  background-color: 0;
  padding: 2rem;
}

header {
  background-color: var(--bg);
  padding-top: 1rem;
}

.header-back {
  margin: 0rem 2rem;
  padding: 1rem 0rem;
}

.header-product {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 95%;
  margin: auto;
}

.header-product-links {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.gone {
  visibility: hidden;
  font-size: 0.001rem;
}

.tobedelted {
  height: 1000px;
}

.flexforsidebarandcontent {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.mypic {
  max-width: 23rem;
  max-height: 100%;
  /* Set a maximum height */
  height: auto;
  /* Allow the image to resize proportionally */
  object-fit: contain;
}

.aboutme-whoisthisdude {
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.aboutme-div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem;
}

.aboutme-text {
  max-width: 25rem;
}

.mystrengthtext {
  max-width: 50rem;
}

.NDA-sections {
  display: flex;
  flex-direction: row;
  padding: 0.8rem;
  border-radius: 0.5rem;
  background-color: #32240b;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.NDA-sections p {
  color: #c8902a;
  font-weight: 400;
}
.NDA-sections img {
  padding-top: 0.2rem;
}

.othercasestudy-section {
  max-width: 800px;
  margin: auto;
  padding-bottom: 10rem;
}

.othercasestudy-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

.othercasestudy-img {
  width: 100%;
  border-radius: 1rem;
}

.othercasestudy-container {
  display: flex;
  flex-direction: column;
}

.infobox-othercasestudy {
  max-width: 25rem;
  padding-top: 0.5rem;
}

hr {
  border: 0.3px solid rgb(39, 39, 39);
  /* Line color */
  margin: 20px 0px 4rem 0px;
  /* Space around the line */
}

.showborder {
  border: 1px solid red;
  padding: 4px;
}

.chipsskills {
  border: 1px solid rgb(66, 66, 66);
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-radius: 30rem;
  display: inline-block;
  color: grey;
  margin: 0.3rem;
}
.chipsskills:hover {
  background-color: var(--bg);
  color: palegoldenrod;
  border: 0px;
}

.chipsskills-reverse {
  border: 1px solid rgb(66, 66, 66);
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-radius: 30rem;
  display: inline-block;
  color: grey;
  margin: 0.3rem;
}
.chipsskills-reverse:hover {
  background-color: rgb(184, 150, 87);
  color: rgb(105, 68, 0);
  border: 0px;
}

/* WebKit Browsers */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1e1e1e;
}

::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5e5e5e;
}

.nointeraction {
  pointer-events: none;
}

.designingfordiv {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.center {
  margin: auto;
}

.duiconbigbox {
  width: 2.3rem;
  height: 2.3rem;
}

.poloroid1 {
  position: absolute;
  left: -11rem;
  top: 10%;
  transform: rotate(10deg);
  transition: all 0.3s ease;
}
.poloroid1:hover {
  left: 6rem;
  scale: 1.2;
  transform: rotate(-3deg);
}

.poloroid2 {
  position: absolute;
  left: -10rem;
  top: 30%;
  transform: rotate(-8deg);
  transition: all 0.3s ease;
}
.poloroid2:hover {
  left: 2rem;
  transform: rotate(2deg);
}

.poloroid3 {
  position: absolute;
  right: -11rem;
  top: 10%;
  transform: rotate(-6deg);
  transition: all 0.3s ease;
}
.poloroid3:hover {
  right: 3rem;
  transform: rotate(0deg);
}

.poloroid4 {
  position: absolute;
  right: -10rem;
  top: 30%;
  transform: rotate(8deg);
  transition: all 0.3s ease;
}
.poloroid4:hover {
  right: 2rem;
  transform: rotate(5deg);
}

.contentbox-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.pieces-div-text {
  margin: auto;
  width: 700px;
  max-width: 100%;
}

.pieces-div-img {
  margin: auto;
  width: 1000px;
  max-width: 100%;
  padding: 5px;
}

.pieces-div-coverimg {
  margin: auto;
  max-width: 2440px !important;
  padding: 1rem;
}

.fitisnide {
  margin: auto;
  max-width: 100%;
}

.padding-img {
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
}

.bigbox2 {
  margin-top: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bigtext2 {
  font-family: "Inter Tight";
  font-size: 7.5rem;
  font-weight: 450;
  letter-spacing: -0.4rem;
  line-height: 94%;
  color: var(--h1);
}

.Head2container {
  background: radial-gradient(52.56% 68.18% at 50.05% 100%, rgba(230, 229, 236, 0.5764705882) 0%, #FFF 100%);
  padding: 5% 0rem 5% 0rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  border: 0px solid grey;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  transition: "background 4s ease";
}

.designingfordiv2 {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

#bio {
  text-align: center;
}

.subtext-under-bigtext2, .currenlydesigningtext {
  font-size: 1.5rem;
  max-width: 55rem !important;
  font-weight: 320 !important;
  letter-spacing: 0rem !important;
  color: var(--text);
}

.currenlydesigningtext {
  max-width: 100% !important;
  text-wrap: nowrap !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

button {
  padding: 1rem 2.5rem;
  color: #384151;
  border: 1px solid #D2D5DB;
  cursor: pointer;
  font-family: "Instrument Sans", "sans-serif";
  display: flex;
  align-items: center;
  gap: 0.25em;
  white-space: nowrap;
  border-radius: 2rem;
  position: relative;
  transition: scale var(--transition);
  transform-style: preserve-3d;
  perspective: 100vmin;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
}
button:active {
  scale: 1;
}
button:hover {
  background-color: var(--primary);
  color: white;
  border: 1px solid #936BFF;
  scale: 1.2;
  rotate: 0deg;
}

.flyingpolo2 {
  margin-top: 10rem;
  padding-top: 200rem;
  rotate: -5deg;
  padding-left: -20rem;
}

.product-header-imagegrid-box {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.border {
  background-color: 0;
  padding: 2rem;
  margin: 1rem;
}

.animatedparagraph {
  font-size: 3.5rem !important;
  font-weight: 350 !important;
  line-height: 130% !important;
  letter-spacing: 0rem;
  color: #384151 !important;
  position: sticky;
  top: 20% !important;
  padding-bottom: 20rem;
  text-align: left;
}

.border-red {
  border: 1px solid red !important;
}

.copy-success {
  font-family: "Instrument Sans";
  font-size: 1rem;
  padding-left: 0.5rem;
  color: #936BFF;
}

.button-div {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.head3container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5rem 0rem 5rem 0rem;
  gap: 1rem;
}

@media screen and (max-width: 1000px) {
  body {
    overflow-x: hidden !important;
  }
  .designingfordiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .telecomtext {
    display: none;
  }
  .openarrow {
    display: none;
  }
  .tiltedvid {
    transform: scale(0);
    display: none !important;
  }
  .letsmakeithappen {
    max-width: 20rem;
  }
  .contact-container {
    column-gap: 0.5rem;
    row-gap: 4rem;
    flex-wrap: wrap;
    margin: 1rem;
  }
  .even-spacing-project {
    margin-top: 2rem;
  }
  .grow-rotate-reverse {
    transform: scale(1) rotate(0deg) !important;
  }
  .grow-rotate-reverse:hover {
    transform: scale(1) rotate(0deg) !important;
  }
  .grow-rotate, .chipsskills {
    transform: scale(1) rotate(0deg) !important;
  }
  .grow-rotate:hover, .chipsskills:hover {
    transform: scale(1) rotate(0deg) !important;
  }
  .project-margintop {
    margin-top: 3rem;
  }
  .grid-container {
    display: block;
  }
  .new-skills-section,
  .aboutme-section,
  #precision-section,
  .new-skills-section {
    display: none !important;
  }
  .wrapper {
    padding: 0.5rem;
  }
  .inner-contact-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    justify-content: center;
  }
  .inner-contact-container p {
    text-align: center;
  }
  .bigtext2 {
    font-family: "Inter Tight";
    width: 100%;
    font-size: 6rem;
    font-weight: 450;
    letter-spacing: -0.4rem;
    line-height: 94%;
    color: var(--h1);
  }
  button {
    padding: 1rem 2.5rem;
    color: var(--grey700);
    border: 1px solid #D2D5DB;
    cursor: pointer;
    font-family: "Instrument Sans", "sans-serif";
    display: flex;
    align-items: center;
    gap: 0.25em;
    white-space: nowrap;
    border-radius: 2rem;
    position: relative;
    transition: scale var(--transition);
    transform-style: preserve-3d;
    perspective: 100vmin;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);
  }
  button:active {
    scale: 1;
  }
}
@media screen and (min-width: 1010px) {
  .maxwidth-container, .header-container, .maxwidth-container-withflex, .maxwidth-container-head3 {
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
  }
  .animatedparagraph {
    font-family: "Instrument Sans";
    font-size: 3rem;
    font-weight: 350;
    line-height: 110%;
    letter-spacing: -0.08rem;
    color: #202937;
    position: sticky;
    top: 30%;
    padding-bottom: 20rem;
    text-align: left;
  }
}
@media screen and (min-width: 1400px) {
  .maxwidth-container, .header-container, .maxwidth-container-withflex, .maxwidth-container-head3 {
    max-width: 1200px;
    margin: auto;
    padding: 1rem;
  }
  .animatedparagraph {
    font-family: "Instrument Sans";
    font-size: 3rem;
    font-weight: 350;
    line-height: 110%;
    letter-spacing: -0.08rem;
    color: #202937;
    position: sticky;
    top: 30%;
    padding-bottom: 20rem;
    text-align: left;
  }
}
@media screen and (min-width: 2000px) {
  .maxwidth-container, .header-container, .maxwidth-container-withflex, .maxwidth-container-head3 {
    max-width: 1440px;
    margin: auto;
    padding: 1rem;
  }
  .bigbox2 {
    margin-top: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .bigtext2 {
    font-family: "Inter Tight";
    font-size: 8rem;
    font-weight: 450;
    letter-spacing: -0.4rem;
    line-height: 94%;
    color: var(--h1);
  }
  .animatedparagraph {
    font-family: "Instrument Sans";
    font-size: 4rem;
    font-weight: 350;
    line-height: 110%;
    letter-spacing: -0.08rem;
    color: #202937;
    position: sticky;
    top: 30%;
    padding-bottom: 20rem;
    text-align: left;
  }
}
.footer-section {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 6rem 5rem;
}

.footer_innerbox {
  display: flex;
  flex-direction: row;
}

.box1_footer {
  grid-column: 1/span 6;
}

.box2_footer {
  color: var(--primary) !important;
  grid-column: 8/span 2;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.box3_footer {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.text-cover {
  padding: 2rem 6rem;
  border-radius: 100rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #E9E8F0;
}

.copyemailbutton {
  background-color: #FEDF6F;
  color: #443500 !important;
  border: none !important;
  transform: rotate(-4deg) translate(0rem, -1.5rem);
  font-family: "Permanent Marker", cursive !important;
  padding: 0.8rem 2rem !important;
}
.copyemailbutton:hover {
  background-color: #F8DA6E !important;
  border: none !important;
  color: #443500 !important;
}

.footer-text, .footer-text-link {
  color: #6a638c;
  font-weight: 450;
  font-size: 5rem !important;
}

.footer-text-link:hover {
  color: #936BFF;
  text-decoration: underline;
}

.line-border {
  height: 1px;
  /* Thickness of the line */
  background-color: #E5E7EB;
  /* Line color */
  margin-left: 4vw;
  margin-right: 4vw;
}

.lowerbox {
  max-width: 2500px;
  display: flex;
  margin: auto;
  flex-direction: row;
  justify-content: space-between;
  padding: 5rem 10vw;
}